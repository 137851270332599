<template>
  <div class="jumbotron p-5 rounded-lg" style="margin-bottom: 2em">
    <h1 class="display-4">What can you do with RML?</h1>
  </div>

  <!-- Services/Programs -->
  <div class="container-fluid bg-3 text-center">
    <div  class="row">
      <div class="col-sm-3">
        <i class="fa fa-book fa-4x" aria-hidden="true"></i>
        <h4>Books</h4>
        <p>We offer numerous types of reading material including: books, magazines, audiobooks, and eBooks. Books, audiobooks and magazines may be checked out for a 3 week period.
          <br>You may access your account online to view what you have out, renew your books or place a hold.
          <br>eBooks, audiobooks, and digital magazines can be checked out using <a href="http://fuelyourmind.lib.overdrive.com/D3398C5C-5E54-44D2-8607-70F9E54AF115/10/50/en/Default.htm" target="_blank">Libby</a>
        </p>
      </div>
      <div class="col-sm-3">
        <i class="fa fa-film fa-4x" aria-hidden="true"></i>
        <h4>Movies</h4>
        <p>We carry both regular DVDs and Blu-rays.
          <br>[50¢ per day late fee charge].
          <br>In addition we do carry educational videos that check out for one week.
        </p>
      </div>
      <div class="col-sm-3">
        <i class="fa fa-music fa-4x" aria-hidden="true"></i>
        <h4>Music CDs</h4>
        <p>We have a collection of musical CDs available for check out. Patrons are limited to 5 cds at one time. There is no charge for these cds.
        </p>
      </div>
      <div class="col-sm-3">
        <i class="fa fa-truck fa-4x" aria-hidden="true"></i>
        <h4>Interlibrary Loan Program</h4>
        <p><a href="http://www.mel.org" target="_blank">MeL</a> is the Michigan Electronic Library System. It is the interloan system operated by the state that allows access to almost every library in the state--public, private and universities!
          If we do not own an item you want, you can request it through <a href="http://www.mel.org" target="_blank">MeL</a>. You can request the item yourself or contact us.
          <br>You must be a resident of Richland Township or a contracted township to use this service.
        </p>
      </div>
    </div>
  </div>

  <br>

  <div class="container-fluid bg-3 text-center">
    <div class="row">
      <div class="col-sm-3">
        <i class="fa fa-fax fa-4x" aria-hidden="true"></i>
        <h4>Faxing</h4>
        <p>We do provide faxing for a fee:
          <br>Coversheet is free
          <br>1 page is $1
          <br>2-10 pages is $2
          <br>11+ pages is $5
        </p>
      </div>
      <div class="col-sm-3">
        <i class="fa fa-wifi fa-4x" aria-hidden="true"></i>
        <h4>Internet Access</h4>
        <p>We have five unfiltered computers for use by patrons over 18 and four that are filtered for those under 18. These computers are available to the public as an informational and educational resource. Speak to the circulation desk about using computers.
          <br><b>Wi-Fi</b>: unfiltered wireless internet is available to those 18 and older. You will need to ask at the desk for the password to log on. Anyone under 18 must be with a parent to receive the password.
          <br>
        </p>
      </div>
      <div class="col-sm-3">
        <i class="fa fa-print fa-4x" aria-hidden="true"></i>
        <h4>Printing/Copying</h4>
        <p><b>Printing</b> is available to all patrons through our computers.
          <br>Pricing: 3 Free Sheets of paper
          <br>10¢ every sheet after
          <br><b>Copying</b> is done through our copy machine
          <br>Pricing: 10¢ for regular sheets
          <br>15¢ for legal and ledger sheets
          <br>25¢ for color copies
          <br>[<b>All Printing through computer is in Black and White</b>]
          <br>[<b>Wireless Printing through copy machine available including option of color</b>>]
        </p>
      </div>
      <div class="col-sm-3">
        <i class="fa fa-download fa-4x" aria-hidden="true"></i>
        <h4>Scanning</h4>
        <p>Scanning is free of charge
          <br>Formats available: PDF & JPEG
          <br><b>[FLASHDRIVE or EMAIL ADDRESS required]</b>
        </p>
      </div>
    </div>
  </div>

  <br>

  <div class="container-fluid bg-3 text-center">
    <div class="row">
      <div class="col-sm-3">
        <i class="fa fa-child fa-4x" aria-hidden="true"></i>
        <h4>Story Hour</h4>
        <p><b>For ages 3 to 5 years old.</b>
          <br>During this hour children listen to stories, do finger plays, sing songs and make crafts.
          <br>Meets every Monday (late Sept-mid May) from 10:30am-11:30am</p>
      </div>
      <div class="col-sm-3">
        <i class="fa fa-puzzle-piece fa-4x" aria-hidden="true"></i>
        <h4>Play to Learn</h4>
        <p><b>For ages birth to 5 years old.</b> Parents/guardians stay and socialize, sing, and play all while learning.
          <br>Meets every Tuesday (late Sept-mid May) from 10:30am-11:30am</p>
      </div>
      <div class="col-sm-3">
        <i class="fa fa-shopping-cart fa-4x" aria-hidden="true"></i>
        <h4>Fall Book Sale</h4>
        <p>We feature an annual book sale in September. Donations are accepted in August when library announces they accept them and if the materials are in good, usable condition.
          <br><b>[</b>We do <b>not</b> accept text books, old encyclopedias, Readers Digest Condensed version books, magazines, cassettes, or VHS.<b>]</b></p>
      </div>
      <div class="col-sm-3">
        <i class="fa fa-users fa-4x" aria-hidden="true"></i>
        <h4>Friends of the Library</h4>
        <p>The Friends is a group of volutneers who work to support the library. They have an annual meeting in March to plan their year of events. They hold bake sales, book sales and other fundraisers. They also sponser the library's Summer Reading
          Programs.
          <br>Dues are just a $1.00 per year plus any time you are willing to volunteer.</p>
      </div>
    </div>
  </div>
  <br>
</template>

<script>

export default {
  name: 'libraryServices'
}
</script>

<style scoped>
.jumbotron {
  /*background-color: #4e6738;*/
  background-color: #7a9c4e;
  color: #FFFFFF;
  text-align: center;
}

i {
  margin-bottom:.2em;
}

</style>
